import React from 'react'
import { Link } from 'gatsby'

import { EnsureRole } from '../../components/admin/ensure-role'

export default function AdminPage() {
  return (
    <EnsureRole
      userRole="client-admin"
      protectedContent={() => (
        <Link to="/client-admin/users">Manager users</Link>
      )}
    />
  )
}
